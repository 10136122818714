import React, { useEffect, useMemo, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import styles from "./Checkout.module.scss";
import { ErrorBanner, FormGroup, Modal, TextInput } from "src/components";
import Axios from "src/services/api";
import useTaxType from "../tax";
import { formatCurrency } from "src/services/currency";
import { toast } from "react-hot-toast";
import usePopups from "src/services/usePopups";
import "./Checkout.scss";

export default function CheckoutForm({
  product,
  currency,
  country,
  zip,
  state,
  city,
  regionCode,
  ip,

  payWantPrice,

  _upsell,
  user,

  _clientSecret,
  _paymentIntentId,

  discountApplied,
  discount,

  taxPercentage,
  taxCountry,

  selectedVariant,
}: any) {
  const currentSubdomain = window.location.hostname.split(".")[0];
  const stripe = useStripe();
  const elements = useElements();
  const { clientSecret, paymentIntentId } = useParams();

  const upsell = product?.upsell;

  const [email, setEmail] = useState("");
  const [vat, setVat] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const affiliate = searchParams.get("a") || "";

  const { popups, togglePopup } = usePopups(["upsell"]);

  const taxType = useTaxType(taxCountry);

  useEffect(() => {
    if (
      upsell &&
      product?.price === 0 &&
      upsell?.upsellPrice > 0 &&
      !_upsell &&
      !paymentIntentId &&
      !clientSecret
    ) {
      setIsLoading(true);
      Axios.post("/products/update-payment-intent", {
        productId: product?._id,
        country,
        zip,
        state,
        city,
        regionCode,
        currency,
        ip,
        affiliate,

        upsellId: upsell?._id,

        payWantPrice: payWantPrice,

        taxPercentage: taxPercentage || 0,
        vatNumber: vat || 0,
      }).then((res) => {
        setIsLoading(false);

        const searchParams = new URLSearchParams(window.location.search);
        if (affiliate) searchParams.set("a", affiliate || "");
        searchParams.set("productId", product?._id);

        // construct the new URL
        const newUrl = `${window.location.pathname}/${res.data.clientSecret}/${
          res.data.paymentIntentId
        }?${searchParams.toString()}`;
        navigate(newUrl);
      });
    }
  }, [upsell]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (
      (!clientSecret || !paymentIntentId) &&
      (!_clientSecret || !_paymentIntentId)
    ) {
      return;
    }

    Axios.get(
      "/products/payment-intent/" + (paymentIntentId || _paymentIntentId)
    ).then((res) => {
      switch (res.data.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "canceled":
          setMessage("The payment has been canceled.");
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe, paymentIntentId, _paymentIntentId]);

  const [processing, setProcessing] = useState(false);

  const handleUpsell = async (acceptsUpsell: boolean) => {
    const actualPaymentIntentId = paymentIntentId || _paymentIntentId;

    // buy free
    const buyFree =
      (product?.price === 0 && !product?.payWant && !acceptsUpsell) ||
      (product?.payWant && payWantPrice === 0 && !acceptsUpsell);

    // see if discounted price is 0 or negative
    const discountedPrice = discountApplied
      ? product?.payWant
        ? payWantPrice -
          (discount?.valueType === "percentage"
            ? (discount?.value / 100) * payWantPrice
            : discount?.value)
        : product?.price -
          (discount?.valueType === "percentage"
            ? (discount?.value / 100) * product?.price
            : discount?.value)
      : product?.payWant
      ? payWantPrice
      : product?.price;

    const isFree = buyFree || discountedPrice <= 0;

    setProcessing(true);
    if (acceptsUpsell) {
      // create a new payment intent for the upsell
      if (
        (product?.price === 0 && !product?.payWant) ||
        (payWantPrice === 0 && product?.payWant)
      ) {
      } else {
        await toast.promise(
          Axios.post("/products/update-payment-intent", {
            paymentIntentId: actualPaymentIntentId,
            productId: product?._id,
            country,
            zip,
            state,
            city,
            regionCode,
            currency,
            ip,
            affiliate,

            upsellId: upsell?._id,

            payWantPrice: payWantPrice,

            discountId: discount?._id,

            taxPercentage: taxPercentage || 0,
            vatNumber: vat || 0,

            variantId: selectedVariant?._id,
          }),
          {
            loading: "Processing...",
            success: "Succeeded!",
            error: "Error processing",
          }
        );
      }
    } else {
      // discounted HERE
      if ((selectedVariant || discountApplied || taxPercentage) && !isFree) {
        await toast.promise(
          Axios.post("/products/update-payment-intent", {
            paymentIntentId: actualPaymentIntentId,
            productId: product?._id,
            country,
            zip,
            state,
            city,
            regionCode,
            currency,
            ip,
            affiliate,

            upsellId: undefined,

            payWantPrice: payWantPrice,

            discountId: discountApplied ? discount?._id : undefined,

            taxPercentage: taxPercentage,
            vatNumber: vat || 0,

            variantId: selectedVariant?._id,
          }),
          {
            loading: "Processing...",
            success: "Succeeded!",
            error: "Error processing",
          }
        );
      }
    }

    if (
      !email &&
      ((product?.price === 0 && !product?.payWant) ||
        (payWantPrice === 0 && product?.payWant))
    ) {
      setMessage("Please enter your email address.");
      return;
    }

    if (buyFree || discountedPrice <= 0) {
      await toast.promise(
        Axios.post("/products/buy-free", {
          productId: product?._id,
          email,
          country,
          zip,
          state,

          discountId: discount?._id,

          variantId: selectedVariant?._id,
        })
          .then((res) => {
            console.log(res);
            navigate(
              "/checkout/success?redirect_status=succeeded&saleId=" +
                res.data._id
            );
          })
          .catch((err) => {
            console.log(err);
            setMessage(err.response.data.message);
          }),
        {
          loading: "Processing...",
          success: "Succeeded!",
          error: "Error processing",
        }
      );
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://${currentSubdomain}.${
          process.env.REACT_APP_AUTH0_AUDIENCE
        }/checkout/success?paymentIntentId=${
          paymentIntentId || _paymentIntentId
        }`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message!);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
    setProcessing(false);

    if (!error && upsell?.upsellProduct?._id) {
      togglePopup("upsell");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (
      ((payWantPrice === 0 && product?.payWant) ||
        (product?.price === 0 && !product?.payWant)) &&
      email === ""
    ) {
      setMessage("Please enter your email address.");
      return;
    }

    if (upsell?.upsellProduct?._id) {
      togglePopup("upsell");
      return;
    } else {
      handleUpsell(false);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const taxAmount =
    _clientSecret || !user.taxEnabled
      ? 0
      : parseFloat(product?.payWant ? payWantPrice : product?.price) *
        (taxPercentage / 100);

  const total = _clientSecret
    ? product?.payWant
      ? payWantPrice
      : product?.price
    : parseFloat(product?.payWant ? payWantPrice : product?.price) + taxAmount;

  // const taxAmount = 0;
  // const total = product?.price;

  const prodPrice = useMemo(
    () =>
      discountApplied
        ? product?.payWant
          ? payWantPrice -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * payWantPrice
              : discount?.value)
          : product?.price -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * product?.price
              : discount?.value)
        : product?.payWant
        ? payWantPrice
        : product?.price,
    [discountApplied, discount, product, payWantPrice]
  );

  useEffect(() => {
    if (message) {
      togglePopup("upsell", false);
    }
  }, [message]);

  return (
    <>
      {message && <ErrorBanner message={message}></ErrorBanner>}
      <form id="payment-form" className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.emailInput}>
          {prodPrice > 0 && (_clientSecret || clientSecret) && (
            <LinkAuthenticationElement
              id="link-authentication-element"
              onChange={(e: any) => setEmail(e?.target?.value)}
            />
          )}
        </div>
        {prodPrice > 0 && (_clientSecret || clientSecret) && (
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions as any}
          />
        )}
        {prodPrice <= 0 && (
          <FormGroup label="Enter your email" error={message || ""}>
            <TextInput
              name="email"
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Email"
              error={message || ""}
            />
          </FormGroup>
        )}
        {prodPrice > 0 && (
          <>
            <br />
            <br />
            <FormGroup label="VAT number (optional)">
              <TextInput
                name="vat"
                type="vat"
                value={vat}
                onChange={(e: any) => setVat(e.target.value)}
                placeholder="VAT number / Tax ID"
              />
            </FormGroup>
          </>
        )}
        <div className={styles.totalSection}>
          <div className={styles.total}>
            <span>Price</span>
            <div>
              {product?.payWant
                ? formatCurrency(payWantPrice, currency, true)
                : product?.price > 0
                ? formatCurrency(product?.price, currency, true)
                : formatCurrency(0, currency)}
            </div>
          </div>
          {/* {taxPercentage !== undefined && (
            <div className={styles.total}>
              <span>
                {taxType} {product?.price > 0 ? `(${taxPercentage}%)` : ""}
              </span>
              <div>
                {product?.price > 0
                  ? formatCurrency(taxAmount, currency)
                  : formatCurrency(0, currency)}
              </div>
            </div>
          )} */}

          {discountApplied && (
            <>
              <div className={styles.total}>
                <span>
                  Discount (
                  {discount?.valueType === "percentage"
                    ? `${discount?.value}% off`
                    : formatCurrency(discount?.value, currency) + " off"}
                  )
                </span>
                <div>
                  -{" "}
                  {formatCurrency(
                    // Discount Amount
                    discount?.valueType === "percentage"
                      ? product?.payWant
                        ? payWantPrice * (discount?.value / 100)
                        : product?.price * (discount?.value / 100)
                      : discount?.value,
                    currency,
                    true
                  )}
                </div>
              </div>
              <div className={styles.total}>
                <span>Subtotal</span>
                <div>
                  {formatCurrency(
                    (product?.payWant ? payWantPrice : product?.price) -
                      (discount?.valueType === "percentage"
                        ? product?.payWant
                          ? payWantPrice * (discount?.value / 100)
                          : product?.price * (discount?.value / 100)
                        : discount?.value),
                    currency,
                    true
                  )}
                </div>
              </div>
            </>
          )}

          {/*
            total -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * total
              : discount?.value)
          */}

          {taxPercentage !== undefined && (
            <div className={styles.total}>
              <span>
                Tax ({taxType} {taxPercentage}%)
              </span>
              <div>
                {formatCurrency(
                  discountApplied
                    ? (product?.payWant
                        ? total -
                          (discount?.valueType === "percentage"
                            ? (discount?.value / 100) * total
                            : discount?.value)
                        : product?.price > 0
                        ? total -
                          (discount?.valueType === "percentage"
                            ? (discount?.value / 100) * total
                            : discount?.value)
                        : 0) -
                        ((product?.payWant ? payWantPrice : product?.price) -
                          (discount?.valueType === "percentage"
                            ? product?.payWant
                              ? payWantPrice * (discount?.value / 100)
                              : product?.price * (discount?.value / 100)
                            : discount?.value))
                    : taxAmount,

                  currency,
                  true
                )}
              </div>
            </div>
          )}

          {!discountApplied && (
            <div className={styles.total}>
              <span>Total</span>
              <div>
                {product?.payWant
                  ? formatCurrency(total, currency, true)
                  : product?.price > 0
                  ? formatCurrency(total, currency, true)
                  : formatCurrency(0, currency)}
              </div>
            </div>
          )}

          {discountApplied && (
            <div className={styles.total}>
              <span>Total</span>
              <div>
                {product?.payWant
                  ? formatCurrency(
                      total -
                        (discount?.valueType === "percentage"
                          ? (discount?.value / 100) * total
                          : discount?.value),
                      currency,
                      true
                    )
                  : total > 0
                  ? formatCurrency(
                      total -
                        (discount?.valueType === "percentage"
                          ? (discount?.value / 100) * total
                          : discount?.value),
                      currency,
                      true
                    )
                  : formatCurrency(0, currency)}
              </div>
            </div>
          )}
        </div>
        <button
          className={styles.submitButton}
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : prodPrice > 0 ? (
              "Pay " +
              (!discountApplied
                ? product?.payWant
                  ? formatCurrency(total, currency, true)
                  : product?.price > 0
                  ? formatCurrency(total, currency, true)
                  : formatCurrency(0, currency, true)
                : product?.payWant
                ? formatCurrency(
                    total -
                      (discount?.valueType === "percentage"
                        ? (discount?.value / 100) * total
                        : discount?.value),
                    currency,
                    true
                  )
                : product?.price > 0
                ? formatCurrency(
                    total -
                      (discount?.valueType === "percentage"
                        ? (discount?.value / 100) * total
                        : discount?.value),
                    currency,
                    true
                  )
                : formatCurrency(0, currency, true))
            ) : (
              "Get it free"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
      </form>
      {/* {true && ( */}
      {(_upsell || popups.upsell) && (
        <Modal
          isUpsell
          renderInLocalPortal="localPoral"
          noX
          onClose={() => {
            handleUpsell(false);
          }}
          title={upsell?.offer}
          footerRightButton1={{
            label: upsell?.secondaryButtonText || "No, thanks",
            disabled: processing,
            variant: "tertiary",
            onClick: () => {
              handleUpsell(false);
            },
          }}
          footerRightButton2={{
            variant: "primary",
            label: upsell?.primaryButtonText || "Yes, add to my order",
            disabled: processing,
            // @ts-ignore
            onClick: (e: any) => {
              e.preventDefault();

              handleUpsell(true);
            },
          }}
        >
          <div className={styles.upsellModalContent}>
            <div className={styles.upsellDescription}>
              {upsell?.upsellDescription}
            </div>
            <div className={styles.upselllProduct}>
              <div className={styles.upsellImageContainer}>
                <img
                  className={styles.image}
                  src={
                    upsell?.upsellProduct.thumbnail ||
                    "https://via.placeholder.com/600?text=Product+Image"
                  }
                  alt={upsell?.upsellProduct.name}
                />
              </div>
              <div className={styles.upsellTextSide}>
                <div className={styles.upsellTitle}>
                  {upsell?.upsellProduct.name}
                </div>
                <a className={styles.navBrand}>
                  <img
                    src={user.picture}
                    alt="Creator Profile Picture"
                    className={styles.creatorProfilePicture}
                  />
                  <span className={styles.creatorName}>
                    {user.firstName || user.name} {user.lastName}
                  </span>
                </a>
                <div className={styles.upsellProductPrice}>
                  {formatCurrency(
                    product?.upsell
                      ? product?.upsell?.upsellPrice
                      : upsell?.upsellPrice,
                    currency
                  )}
                </div>
              </div>
            </div>
            {prodPrice <= 0 && upsell?.upsellPrice > 0 && !_upsell && (
              <div className={styles.upselllProduct + " " + styles.ch}>
                <PaymentElement
                  id="payment-element"
                  options={paymentElementOptions as any}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
